import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    Chip,
    Checkbox,
    Autocomplete,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { handleChangePaRegexs } from '../handleChangeRegexes';
import { aetnaDefaultData, paOptionalSuffixText, paTextFieldHelperText,paValidDateText } from '../../../../utils/staticData';
import { sharedStyles } from '../../../../sharedStyles';
import GreenButton from '../../../claims/GreenButton';
import toast from 'react-hot-toast';
import { styles } from './styles';

const PatientForm = () => {
    const [formData, setFormData] = useState(aetnaDefaultData?.patientInfo);

    const [errors, setErrors] = useState({dob:false})

    const handleChange = (field) => (event) => {
        const { value } = event.target;

        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            birthDate: date
        });
    };

    const handleDiagnosesChange = (event, newValue) => {
        setFormData({
            ...formData,
            diagnoses: newValue
        });
    };
    

    const handleSubmit = () => {
        if(!formData?.birthDate){
            toast.error('please enter required date of birth to continue')
            setErrors((prev)=>({...prev,dob:true}))
        }
        else setErrors((prev)=>({...prev,dob:false}))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Patient Information
                </Typography>

                <Grid container spacing={3}>
                    {/* Last Name */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Patient Last Name"
                            value={formData.lastName}
                            onChange={handleChange('lastName')}
                            inputProps={{
                                maxLength: 60
                            }}
                            helperText={paTextFieldHelperText}
                        />
                    </Grid>

                    {/* First Name */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Patient First Name"
                            value={formData.firstName}
                            onChange={handleChange('firstName')}
                            inputProps={{
                                maxLength: 35
                            }}
                            helperText={paTextFieldHelperText}
                        />
                    </Grid>

                    {/* Suffix */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Patient Suffix"
                            value={formData.suffix}
                            onChange={handleChange('suffix')}
                            inputProps={{
                                maxLength: 10
                            }}
                            helperText={paOptionalSuffixText}
                        />
                    </Grid>

                    {/* Birth Date */}
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Patient Date of Birth"
                            sx={errors?.dob ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                            value={formData.birthDate}
                            onChange={handleDateChange}
                            minDate={dayjs('1870-01-01')}
                            maxDate={dayjs('2024-12-12')}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    helperText: paValidDateText
                                }
                            }}
                        />
                    </Grid>

                    {/* Gender */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Patient Gender</InputLabel>
                            <Select
                                value={formData.genderCode}
                                label="Patient Gender"
                                onChange={handleChange('genderCode')}
                            >
                                {aetnaDefaultData?.patientGenders?.map(({value,label}) => {
                                    return(
                                        <MenuItem value={value}>{label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Relationship to Subscriber */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Patient's Relationship to Subscriber</InputLabel>
                            <Select
                                value={formData.subscriberRelationshipCode}
                                label="Patient's Relationship to Subscriber"
                                onChange={handleChange('subscriberRelationshipCode')}
                            >
                                {aetnaDefaultData?.patientRelationToSubscriber?.map(({value,label}) => {
                                    return(
                                        <MenuItem value={value}>{label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* diagnose */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                multiple
                                options={aetnaDefaultData?.dummyDiagnosis?.map(d => d.code)}
                                getOptionLabel={(code) => {
                                    const diagnosis = aetnaDefaultData?.dummyDiagnosis?.find(d => d.code === code);
                                    return diagnosis ? `${diagnosis.code} - ${diagnosis.description}` : code;
                                }}
                                value={formData.diagnoses}
                                onChange={handleDiagnosesChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Diagnoses"
                                        placeholder="Select diagnoses"
                                    />
                                )}
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                <Grid
                      item
                      xs={12}
                      display={'flex'}
                      sx={sharedStyles?.justifyEnd}
                      marginTop={5}
                  >
                      <GreenButton onClick={handleSubmit} >
                           Next
                      </GreenButton>
                  </Grid>
            </Paper>
            
        </LocalizationProvider>
    );
};

export default PatientForm;
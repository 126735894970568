import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    IconButton,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import GreenButton from '../../../claims/GreenButton';
import { sharedStyles } from '../../../../sharedStyles';
import { styles } from './styles';
import { aetnaDefaultData } from '../../../../utils/staticData';

const ProcedureForm = () => {
    const [procedures, setProcedures] = useState(aetnaDefaultData?.procedures);


    const handleProcedureChange = (index, field) => (event) => {
        const newProcedures = [...procedures];
        newProcedures[index][field] = event.target.value;
        setProcedures(newProcedures);
    };



    const addProcedure = () => {
        if (procedures.length < 5) {
            setProcedures([...procedures, aetnaDefaultData?.procedures?.[0]]);
        } else {
            toast.error('Maximum 5 procedures allowed');
        }
    };

    const removeProcedure = (index) => {
        if (procedures.length > 1) {
            const newProcedures = procedures.filter((_, i) => i !== index);
            setProcedures(newProcedures);
        }
    };

    const handleSubmit = () => {
        const hasEmptyRequired = procedures.some(proc => 
            !proc.code ||
            !proc.qualifierCode || 
            !proc.quantity 
        );

        if (hasEmptyRequired) {
            toast.error('Please fill in all required fields');
            return;
        }

        console.log({ procedures });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Procedures Information
                </Typography>

                {procedures.map((procedure, index) => (
                    <Box key={index} sx={styles?.commonPaperContainer}>
                        <Grid container spacing={3}>
                            <Grid item xs={11}>
                                <Typography variant="h6">Procedure {index + 1}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton 
                                    onClick={() => removeProcedure(index)}
                                    disabled={procedures.length === 1}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Procedure Qualifier</InputLabel>
                                    <Select
                                        value={procedure.qualifierCode}
                                        label="Procedure Qualifier"
                                        onChange={handleProcedureChange(index, 'qualifierCode')}
                                    >
                                        <MenuItem value="HC">
                                            Health Care Financing Administration Common Procedural Coding System (HCPCS) Codes
                                        </MenuItem>
                                        <MenuItem value="N4">
                                        National Drug Code in 5-4-2 Format
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Procedure Code"
                                    value={procedure.code}
                                    onChange={handleProcedureChange(index, 'code')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Modifier 1"
                                    value={procedure.modifierCode1}
                                    onChange={handleProcedureChange(index, 'modifierCode1')}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Procedure Quantity Type</InputLabel>
                                    <Select
                                        value={procedure.quantityTypeCode}
                                        label="Procedure Service Type"
                                        onChange={handleProcedureChange(index, 'quantityTypeCode')}
                                    >
                                        {aetnaDefaultData?.quantityTypeCodes.map(type => (
                                            <MenuItem key={type.code} value={type.code}>
                                                {type.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Procedure Quantity"
                                    value={procedure.quantity}
                                    onChange={handleProcedureChange(index, 'quantity')}
                                    inputProps={{ 
                                        pattern: '^[0-9]{1,15}$',
                                        maxLength: 15
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                ))}

                <Box sx={{ mb: 4 }}>
                    <IconButton onClick={addProcedure} color="primary">
                        <AddIcon /> Add Procedure
                    </IconButton>
                </Box>

                <Grid
                      item
                      xs={12}
                      display={'flex'}
                      sx={sharedStyles?.justifyEnd}
                  >
                      <GreenButton onClick={handleSubmit} >
                           Next
                      </GreenButton>
                  </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default ProcedureForm;
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { styles } from './configuration/style';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { colors } from '../../utils/colors';
import { sharedStyles } from '../../sharedStyles';


const PriorAuthTableC = ({ data, error }) => {
    const [rowData, setRowData] = useState(null);
    const navigate = useNavigate()
    
    useEffect(() => {
        if (data) {
            const rowsData = data?.map(({ transactionDate, patientData: { firstName, lastName, phone, dateOfBirth }, payerData: { payerName, eligibility },srRequestID,patientId,apiResponseMessage }) => {
                return ({
                    TransactionDate: transactionDate || "",
                    Date: new Date(),
                    Patient: `${firstName} ${lastName}`,
                    Phone: phone || '--------',
                    DOB: dateOfBirth,
                    Insurance: payerName || '',
                    Status: apiResponseMessage,
                    Details: '',
                    patientId,
                    srRequestID
                })
            })
            setRowData(rowsData);
        } else {
            setRowData([]);
        }
    }, [data, error])

    const statusCellRenderer = (params) => {
        const statusValue = params.value;
        return <span style={{...styles?.statusStyle,
            color:params?.data?.Status === 'Denied' ? colors?.red : 
            params?.data?.Status === 'Pending' ? colors?.yellow : 
            colors?.green}}>{statusValue}</span>;
    };

    const EligibilityDetailsButton = (params) => {
        if (params?.data) {
            return (
                <Button
                    className="primary"
                    style={{...styles?.eligibilityDetailsButton,
                        backgroundColor: params?.data?.Status === 'Denied' ? colors?.red : colors?.green
                    }}
                    onClick = {()=>{
                          navigate(`/priorAuthDetails?sr=${params?.data?.srRequestID}&pId=${params?.data?.patientId}&tDate=${params?.data?.TransactionDate}`)
                    }}
                >
                   <Typography style={{color:colors?.white}}>{'View Details'}</Typography>
                </Button>
            );
        }
        return <div></div>;
    };

    const handleEligibilityButtonClick = (data) => {
        navigate({
            pathname: '/Eligibilitydetails1',
            search: `?rowData=${encodeURIComponent(JSON.stringify(data))}`,
        });
    };

    const gridOptions = {
        pagination: true,
        paginationPageSize:  10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    };

    const columnDefs = () => {
        return [
            { headerName: 'Transaction Date', field: 'TransactionDate', filter: true, resizable: false },
            { headerName: 'Date', field: 'Date', sortable: true, filter: true, width: 201, resizable: false },
            { headerName: 'Patient', field: 'Patient', filter: true, resizable: false },
            { headerName: 'Phone', field: 'Phone', filter: true, width: 123, resizable: false },
            { headerName: 'Date of Birth', field: 'DOB', width: 120, resizable: false },
            { headerName: 'Insurance Carrier', field: 'Insurance', width: 165, filter: true, resizable: false },
            { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true, width: 100, resizable: false },
            { headerName: 'Details', field: 'Details', width: 450, filter: true, cellRenderer: EligibilityDetailsButton, flex: 1, minWidth: 143, resizable: false },
        ];
    };

    return (
        <Box className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
                overlayNoRowsTemplate='No Prior Auth Found!'
            />
        </Box>
    )
}

export default PriorAuthTableC

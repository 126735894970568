import React, { useEffect, useState } from 'react'
import { get_GetPatientDataById, getInsurance_Details } from '../../services/addPatient';
import { useParams } from 'react-router-dom';
import { Box} from '@mui/material';
import dayjs from 'dayjs';
import InsuranceSubTabTable from '../../components/table/InsuranceSubTabTable';



const InsuranceDetails = () => {
    const [isLoading, setIsloading] = useState(false)
    const [err, setErr] = useState(false)
    const [patient, setPatient] = useState({
        patientFirstName: '',
        patientLastName: '',
        patientDOB: dayjs(),
        transactionDate: dayjs(),
        dateOfService: dayjs(),
        patientState: 'VA',
    });
    const { id } = useParams();
    const [insurance, setInsurance] = useState({});

    const getUser = async () => {
        try {
            const response = await get_GetPatientDataById(id);
            const { firstName, lastName, dob } = response?.data;
            setPatient({
                ...patient,
                patientFirstName: firstName,
                patientLastName: lastName,
                patientDOB: dayjs(dob),
                patientState: 'VA'
            });
        } catch (error) {
            console.log(error)
        }
    };



    const handleSubmit = async () => {
        setIsloading(true)
        const { transactionDate, patientDOB } = patient;
        const x = dayjs(transactionDate).format('YYYY-MM-DD');
        const y = dayjs(patientDOB).format('YYYY-MM-DD');

        const obj = {
            ...patient,
            transactionDate: x,
            patientDOB: y,
        }
        try {
            const response = await getInsurance_Details(obj);
             setInsurance(response)
        } catch (error) {
            setErr(true)
            console.log(error)
        } finally {
            setIsloading(false)
        }
    }


    useEffect(() => {
        getUser();
    }, [id]);

    useEffect(() => {
        if (patient.patientFirstName) {
            handleSubmit();
        }
    }, [patient.patientFirstName]);


    return (
        <Box>
            {/* <Grid container rowSpacing={3} columnSpacing={8}>
                {topCardsItems?.map((elem, index) => {
                    return (
                        <Grid item xs={12} sm={6} lg={3} key={index} >
                            <Box sx={{ boxShadow: '0px 4px 8px #5E8A75', borderRadius: 2, bgcolor: 'white', height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, cursor: 'pointer', }}>
                                {elem?.icon}
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', color: colors?.themeGreen }}>{elem?.name}</Typography>
                                    <Typography>{elem?.value}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid> */}
            <InsuranceSubTabTable data={insurance} Error={err} />
        </Box>
    )
}

export default InsuranceDetails

import { Box, IconButton } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { sharedStyles } from "../../sharedStyles";
import UseWidth from "../../custom-hooks/UseWidth";

const IconCellRenderer = ({ displayName }) => {
    return (
        <div className="flex">
            <span>{displayName}</span>
            <IconButton>
                <KeyboardArrowDownIcon fontSize="small" />
            </IconButton>
        </div>
    )
}

const ActionBtn = () => {
    return (
        <div style={{ paddingTop: '0.5em' }}>
            <MoreVertIcon />
        </div>
    )
}


const SyncTable = ({ pagename, data }) => {
    const [rowData, setRowData] = useState(null)
    const {width} = UseWidth();



    const columnDefs = () => {
        if (pagename === "syncpatient") {
            return [
                { headerName: 'ID#', field: 'ID', width: 150, filter: true, resizable: false },
                { headerName: 'Appt', field: 'Appt', width: 150,filter: true, resizable: false },
                { headerName: 'Patient', field: 'Patient', filter: true, resizable: false },
                { headerName: 'Phone', field: 'Phone', filter: true, resizable: false },
                { headerName: 'DOB', field: 'DOB', filter: true , resizable: false},
                { headerName: 'System', field: 'System', width: 150, resizable: false },
                { headerName: 'Insurance', field: 'Insurance', width: 150, resizable: false },
                { headerName: 'Action', field: 'Action', width: 150, cellRenderer: ActionBtn, resizable: false },
            ];
        }
    };




    const gridOptions = {
        pagination: true,
        paginationPageSize: width <= 900 ? 20 : 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    };



    useEffect(() => {
        if (data) {
            const rowData = data.map(({ firstName, lastName, patientInsuranceDetails, dob, phone, ssn, id }) => ({
                ID: id || '----',
                Appt: '----',
                Patient: firstName + ' ' + lastName || '----',
                Phone: phone || '----',
                DOB: dob || '----',
                System: '----',
                Insurance: patientInsuranceDetails || '----',
                Action: ''
            }))
            setRowData(rowData)
        }
    }, [])


    return (
            <Box className="ag-theme-quartz"
                sx={sharedStyles?.responsiveTableHeight}  >
                <AgGridReact
                    columnDefs={columnDefs()}
                    rowData={rowData}
                    gridOptions={gridOptions}
                />
            </Box>
    )
}

export default SyncTable;
import { colors } from "../../utils/colors";

export const styles = {
    eligibilityDetailsButton:{ 
        backgroundColor: colors?.green, 
        border: 'none', 
        color: colors?.white, 
        fontSize: 'small',
     },
      statusStyle: {
        backgroundColor: colors?.white,
        color: colors?.green,
      },
      paListBox : {
        maxHeight: 200, 
        overflow: 'auto',
      }
    }
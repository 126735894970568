import { Autocomplete, Box, CircularProgress, FormControl, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableHeader from '../../components/TableHeader'
import { sharedStyles } from '../../sharedStyles';
import {  defaultBasicPaDetailFields, paBasicFields } from '../../utils/staticData';
import HorizontalBox from '../../components/shared/HorizontalBox'
import { fetchPayersData, getPatientDetails, handleScroll, handleSelectedPayer, removePayerInfo } from './PaFunctions';
import { styles } from './styles';
import AetnaForm from '../../components/eligibility/paForms/Aetna/AetnaForm';

const PaForm = () => {
    // states
    const [patientSelected, setPatientSelected] = useState(null);
    const [allPayers, setAllPayers] = useState([])
    const [loaders, setLoaders] = useState({ payers: false,patientDetails:false })
    const [selectedPayer, setSelectedPayer] = useState(null)
    const [patientBasicDetails, setPatientBasicDetails] = useState(defaultBasicPaDetailFields)
    const [formType, setFormType] = useState(null)
    const [page, setPage] = useState(1)
    // states to be mapped
    const [patientBasicDetailsFields] = useState(paBasicFields)

    // useEffects
    useEffect(() => {
        fetchPayersData(null,setLoaders,setAllPayers,allPayers,loaders)
    }, [])

    useEffect(()=>{
        patientSelected?.id && getPatientDetails(patientSelected,setLoaders,loaders,setPatientBasicDetails,patientBasicDetails,setSelectedPayer,setFormType)
    },[patientSelected])

    useEffect(()=>{
    fetchPayersData(page,setLoaders,setAllPayers,allPayers,loaders)
    },[page])

    return (
        <Box>
            <TableHeader setFilterPatient={setPatientSelected} idNeeded handleCrossValue={() => removePayerInfo(setPatientBasicDetails,setSelectedPayer,setFormType)}/>
            <Box>
                <form>
                    <Grid container spacing={2}>
                        {/* first row for insurance */}
                        <Grid item xs={12}>
                            <FormControl sx={sharedStyles?.mb2} fullWidth>
                                <Autocomplete
                                    loading={loaders?.payers}
                                    disabled={!allPayers?.length}
                                    options={allPayers?.map(e => e?.payerName)}
                                    getOptionLabel={(option) => option}
                                    value={selectedPayer ? selectedPayer.payerName : null} 
                                    onChange={(e,newValue) => handleSelectedPayer(e, newValue,allPayers,setSelectedPayer,setFormType,setPatientBasicDetails)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Insurance Name"
                                            variant="outlined"
                                            color="success"
                                            value={selectedPayer ? selectedPayer.payerName : null} 
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                  <>
                                                    {loaders?.payers ? (
                                                      <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                  </>
                                                ),
                                              }}
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: styles?.paListBox,
                                        onScroll: (e) => {handleScroll(e,setPage,page)},
                                      }}
                                    
                                />
                            </FormControl>
                        </Grid>

                        
                       {
                       loaders?.patientDetails ? 
                       <HorizontalBox sx={{...sharedStyles?.fullWidth,...sharedStyles?.justifyCenter}} > 
                        <CircularProgress color='success' size={20}/>
                       </HorizontalBox> 
                       :
                            
                        
                            <AetnaForm/>
}
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default PaForm
import React from 'react'
import { sharedStyles } from '../../sharedStyles'
import { Box, Button, Typography } from '@mui/material'


const UserMainPage = ({ handleLogin }) => {


    return (
        <Box sx={[sharedStyles?.flex, sharedStyles?.justifyCenter, sharedStyles?.h100vh, sharedStyles?.backgroundDark, sharedStyles?.gap2em, sharedStyles?.flexWrap]}>
            <Box sx={sharedStyles?.mainPageCard}>
                <img
                    src={require('../../../src/assets/images/appzLogo.png')}
                    alt={`Logo`}
                    style={sharedStyles?.mainPageImg}
                />
                {/* Welcome Text */}
                <Typography variant="h4" component="h1" sx={[sharedStyles?.colorWhite]}>
                    {"Welcome to Doctor Portal"}
                </Typography>
                {/* Login Button */}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleLogin}
                    sx={sharedStyles?.mainPageBtn}
                >
                    Login
                </Button>
            </Box>
            <Box sx={sharedStyles?.mainPageCard}>
                <img
                    src={require('../../../src/assets/images/appzLogo.png')}
                    alt={`Logo`}
                    style={sharedStyles?.mainPageImg}
                />
                <Typography variant="h4" component="h1" sx={[sharedStyles?.colorWhite]}>
                 Welcome to Patient Portal
                </Typography>
                <Typography variant="h6" component="h1" sx={[sharedStyles?.colorWhite, sharedStyles?.mt10px]}>
                    Comming Soon !
                </Typography>
            </Box>
        </Box>
    );
}

export default UserMainPage
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useState } from 'react';
import { capitalizedFirstLetter, failedApiReq } from '../../utils/reusableFunctions';
import { get_GetAllEligibilityDataByNpiNumber } from '../../services/registration';
import {sharedStyles} from '../../sharedStyles'
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { dayJsMdyFormat } from '../../utils/DateFunctions';
import UseWidth from '../../custom-hooks/UseWidth';

const EligibilityPaginatedTable = () => {
    const [gridApi, setGridApi] = useState(null);
    const { width } = UseWidth();
    const perPage = width <= 900 ? 20 : 10;
    const navigate = useNavigate()

    const onGridReady = (params) => {
        setGridApi(params.api);
      };

    useEffect(() => {
        if (gridApi) {
          const dataSource = {
            getRows: async(params) => {
    
              const page = params.endRow / perPage;
              try {
                gridApi.showLoadingOverlay();
                const data = await get_GetAllEligibilityDataByNpiNumber(page, perPage);
                if (data?.eligibilityDtosList) {
                  const dataArray = data.eligibilityDtosList.flatMap(element => {
                    const statuses = element?.comboPayerResponses?.map(response => response?.status || 'N/A') || [];
                    const payerNames = element?.comboPayerResponses?.map(response => response?.payerName || 'N/A') || [];
                    return element?.patientData ? [{
                      ID: element?.patientData?.id,
                      PatientId: element?.patientId,
                      RequestId: element?.requestID,
                      TransactionDate: dayJsMdyFormat(element?.transactionDate) || 'N/A',
                      Date: element?.patientData?.appointments ? dayJsMdyFormat(element?.patientData?.appointments[0]) : 'N/A',
                      Patient: `${capitalizedFirstLetter(element?.patientData?.firstName)} ${capitalizedFirstLetter(element?.patientData?.lastName)}`,
                      Phone: element?.patientData?.phone || 'N/A',
                      DOB: dayJsMdyFormat(element?.patientData?.dateOfBirth) || 'N/A',
                      Insurance: payerNames.length > 0 ? payerNames.join(' / ') : 'N/A',
                      Copay: element?.additionalInfo || 'N/A',
                      Status: statuses.length > 0 ? statuses.join(' / ') : 'N/A',
                    }] : [];
                  });
                      params.successCallback(dataArray, data.count);
                  }
                  else failedApiReq()
              }
              catch(e){
                params.successCallback([], 0);
                failedApiReq(e)
              }
              finally{
                gridApi.hideOverlay();
              }
            }
          }
          gridApi.setDatasource(dataSource);
        }
      }, [gridApi]);

      const statusCellRenderer = (params) => {
        const statusValue = params.value;
        return <span style={styles?.statusStyle}>{statusValue}</span>;
      };

      const EligibilityDetailsButton  = (params) => {
        if(params?.data){return (
          <Button
            className='primary'
            style={styles?.eligibilityDetailsButton}
            onClick={() => handleeligibilityButtonClick(params.data)}
          >
            View Details
          </Button>
        )}
        else <div></div>
      };

      const handleeligibilityButtonClick = (data) => {
        navigate({
          pathname: '/Eligibilitydetails1',
          search: `?rowData=${encodeURIComponent(JSON.stringify(data))}`,
        });
      };

    const columnDefs = () => {
        return [
            // { headerName: 'ID#', field: 'ID', },
            // { headerName: 'PatientId', field: 'PatientId', filter: true },
            // { headerName: 'RequestId', field: 'RequestId', filter: true },
            { headerName: 'Transaction Date', field: 'TransactionDate', filter: true,resizable:false },
            { headerName: 'Date', field: 'Date', sortable: true, filter: true ,width:201,resizable:false},
            { headerName: 'Patient', field: 'Patient', filter: true,resizable:false },
            { headerName: 'Phone', field: 'Phone', filter: true,width:123,resizable:false },
            { headerName: 'Date of Birth', field: 'DOB', width: 120,resizable:false },
            { headerName: 'Insurance Carrier', field: 'Insurance', width: 165, filter: true,resizable:false },
            { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true,width:100,resizable:false },
            { headerName: 'Details', field: 'Details', width: 450, filter: true, cellRenderer: EligibilityDetailsButton, flex:1,minWidth:143,resizable:false},
        ];
    }

    return (
        <Box className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
                <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                rowHeight={60}
                defaultColDef={{ width:236 }}
                columnDefs={columnDefs()}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
              />
        </Box>
    );
};

export default EligibilityPaginatedTable;


import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    IconButton,
} from '@mui/material';
import toast from 'react-hot-toast';
import GreenButton from '../../../claims/GreenButton';
import { sharedStyles } from '../../../../sharedStyles';
import { styles } from './styles';

const RequestTypeForm = () => {
    const [requestType, setRequestType] = useState('');

    const requestTypeValues = [
        {
            code: "RX",
            value: "Pharmacy Prior Authorization"
        }
    ];

    const handleRequestTypeChange = (event) => {
        setRequestType(event.target.value);
    };

    const handleSubmit = () => {
        if (!requestType) {
            toast.error('Please select a valid Request Type.');
            return;
        }

        console.log({ requestType });
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Request Type Information
            </Typography>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required>
                            <InputLabel>Request Type</InputLabel>
                            <Select
                                value={requestType}
                                label="Request Type"
                                onChange={handleRequestTypeChange}
                            >
                                {requestTypeValues.map((item) => (
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <Grid
                item
                xs={12}
                display={'flex'}
                sx={sharedStyles?.justifyEnd}
            >
                <GreenButton onClick={handleSubmit}>
                    Next
                </GreenButton>
            </Grid>
        </Paper>
    );
};

export default RequestTypeForm;

import { colors } from "../../../../utils/colors";

export const styles ={
    commonPaperContainer:{ 
        mb: 4, 
        p: 2, 
        border: `1px solid ${colors?.lightGrayBorder}`, 
        borderRadius: 1 
    },
    parentPaperContainer:{ 
        p: 4, 
        width: '80%', 
        mx: 'auto', 
        my: 4
     }
}
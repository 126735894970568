export const isAlphabetOnly = (value) => /^[a-zA-Z]*$/.test(value);
export const isAlphaNumeric = (value) => /^[a-zA-Z0-9]*$/.test(value);
export const isNumber = (value) => /^(?:[0-9\b]*)$/.test(value);

export const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
};

export const isValidPhoneNumber = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber);
};

export const allowTextOnly = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z\s.-]/g, '');
  };

export const allowNumbersOnly = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, '');
};

export const paBasicString = (value) => /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,60}$/?.test(value);
export const paStringMax35 = (value) => /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,35}$/.test(value);
export const paAdministrativeSuffix = (value) => /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,10}$/.test(value);

import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { handleChangePaRegexs } from '../handleChangeRegexes';
import { aetnaDefaultData,  } from '../../../../utils/staticData';
import { sharedStyles } from '../../../../sharedStyles';
import toast from 'react-hot-toast';
import GreenButton from '../../../claims/GreenButton';
import { styles } from './styles';

const RequestingProvideForm = () => {
    const [formData, setFormData] = useState(aetnaDefaultData?.requestingProviderDefaultData);
    const [errors, setErrors] = useState({npi:false,phone:false,roleCode:false,addressLine1:false,addressLine2:false,city:false,zipCode:false,
        extension:false
    });
    

    const handleChange = (field) => (event) => {
        const { value } = event.target;
        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };


    const checkRequiredFields = () => {
        const requiredFields = ['npi', 'phone', 'roleCode'];
        let hasErrors = false;
        const errors = requiredFields.reduce((acc, field) => {
            if (!formData?.[field]) {
                acc[field] = true;
                hasErrors = true;
            } else {
                acc[field] = false;
            }
            return acc;
        }, {});
        return { hasErrors, errors };
    };
    
    const validateAddressFields = () => {
        const errors = {};
        const { addressLine1, addressLine2,npi,city,zipCode,phone,extension } = formData;
    
        if (addressLine1 && addressLine1.length < 2) {
            toast.error('Enter a valid mailing address from 2 to 55 characters in length.');
            errors.addressLine1 = 'Enter a valid mailing address from 2 to 55 characters in length. (optional)';
        } else {
            errors.addressLine1 = false;
        }
    
        if (addressLine2 && addressLine2.length < 2) {
            toast.error('Enter a valid mailing address from 2 to 55 characters in length.');
            errors.addressLine2 = 'Enter a valid mailing address from 2 to 55 characters in length. (optional)';
        } else {
            errors.addressLine2 = false;
        }
        
        if(npi?.length < 10){
            toast.error('Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4.');
            errors.npi = 'Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4.'
         } 
         else {
            errors.npi = false
        }

         if (city && city.length < 2) {
            toast.error('Enter a valid city name from 2 to 30 characters in length.');
            errors.city = 'Enter a valid city name from 2 to 30 characters in length. (optional)';
        } else {
            errors.city = false;
        }

        if(zipCode){
            if(zipCode?.length < 5 || (zipCode?.length > 5 && zipCode?.length < 9)){
                toast.error("Enter a valid ZIP code containing five numeric digits. The four-digit extension is optional and must also be numeric.")
                errors.zipCode = "Enter a valid ZIP code containing five numeric digits. The four-digit extension is optional and must also be numeric. (optional)"
            }
            else {
                errors.zipCode = false
            }
        }
        else {
            errors.zipCode = false
        }

        if(phone?.length < 10){
            toast.error('Enter a valid phone number containing 10 numeric digits including area code.');
            errors.phone = 'Enter a valid phone number containing 10 numeric digits including area code.';
        } else {
            errors.phone = false;
        }

        if(extension && extension?.length < 10){
            toast.error('Enter a valid phone extension containing up to eight numeric digits. This field is optional.');
            errors.extension = 'Enter a valid phone extension containing up to eight numeric digits. This field is optional.';
        } else {
            errors.extension = false;
        }

        return errors;
    };
    
    const handleSubmit = () => {
       
        const { hasErrors: missingRequiredFields, errors: requiredErrors } = checkRequiredFields();
        setErrors((prev) => ({ ...prev, ...requiredErrors }));
    
        if (missingRequiredFields) {
            toast.error('Please enter all important information');
            return;
        }
    
        const addressErrors = validateAddressFields();
        setErrors((prev) => ({ ...prev, ...addressErrors }));
    
        if (!Object.values({ ...requiredErrors, ...addressErrors }).some(Boolean)) {
            console.log('Form is valid, proceed with submission.');
            // Your form submission logic here
        }
    };
    
    
    
    

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Requesting Provider Information
                </Typography>
                <Grid container spacing={3}>
                    {/* Last Name */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider Last Name"
                            value={formData.lastName}
                            onChange={handleChange('lastName')}
                            inputProps={{ maxLength: 60 }}
                            
                        />
                    </Grid>

                    {/* First Name */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider First Name"
                            value={formData.firstName}
                            onChange={handleChange('firstName')}
                            inputProps={{ maxLength: 35 }}
                            
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider NPI*"
                            value={formData.npi}
                            onChange={handleChange('npi')}
                            inputProps={{ maxLength: 10 }}
                            helperText='Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4'
                            error={errors?.npi}
                            
                        />
                    </Grid>

                     {/* to be fixed properly */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Speciality Code"
                            value={formData.specialtyCode}
                            onChange={handleChange('specialtyCode')}
                            inputProps={{ maxLength: 35 }}
                            
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider Address Line 1"
                            value={formData.addressLine1}
                            onChange={handleChange('addressLine1')}
                            inputProps={{ maxLength: 55 }}
                            error={errors?.addressLine1}
                            helperText={errors?.addressLine1}
                            
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider Address Line 2"
                            value={formData.addressLine2}
                            onChange={handleChange('addressLine2')}
                            inputProps={{ maxLength: 55 }}
                            error={errors?.addressLine2}
                            helperText={errors?.addressLine2}
                            
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider City"
                            value={formData.city}
                            onChange={handleChange('city')}
                            inputProps={{ maxLength: 30 }}
                            error={errors?.city}
                            helperText={errors?.city}
                            
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Requesting Provider State</InputLabel>
                            <Select
                                value={formData.stateCode}
                                label="Requesting Provider State"
                                onChange={handleChange('stateCode')}
                            >
                                {aetnaDefaultData?.aetnRequestingProviderStates?.map(({ value, code }) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider ZIP Code"
                            value={formData.zipCode}
                            onChange={handleChange('zipCode')}
                            inputProps={{ maxLength: 9 }}
                            error={errors?.zipCode}
                            helperText={errors?.zipCode}
                            
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider Contact Name"
                            value={formData.contactName}
                            onChange={handleChange('contactName')}
                            inputProps={{ maxLength: 60 }}
                            
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider Contact Phone*"
                            value={formData.phone}
                            onChange={handleChange('phone')}
                            inputProps={{ maxLength: 10 }}
                            error={errors?.phone}
                            helperText={errors?.phone}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Requesting Provider Contact Extension"
                            value={formData.extension}
                            onChange={handleChange('extension')}
                            inputProps={{ maxLength: 8 }}
                            error={errors?.extension}
                            helperText={errors?.extension}
                        />
                    </Grid>


                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <InputLabel>Requesting Provider Type*</InputLabel>
                            <Select
                                value={formData.roleCode}
                                label="Requesting Provider Type"
                                onChange={handleChange('roleCode')}
                                error={errors?.roleCode}
                            >
                                {aetnaDefaultData?.roleCodeVals?.map(({ value, code }) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid
                      item
                      xs={12}
                      display={'flex'}
                      sx={sharedStyles?.justifyEnd}
                      marginTop={5}
                  >
                      <GreenButton onClick={handleSubmit} >
                           Next
                      </GreenButton>
                  </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default RequestingProvideForm;

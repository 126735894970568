import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports'
import '@aws-amplify/ui-react/styles.css';
import { Auth0Provider } from '@auth0/auth0-react';
import config from './config';
import { NotificationProvider } from './context/NotificationContext';

// Use apiUrl and auth0 based on the environment


const root = ReactDOM.createRoot(document.getElementById('root'));
// Use apiUrl and auth0 based on the environment
const apiUrl = process.env.REACT_APP_AUTH0_REDIRECTURI;

root.render(
  // <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: apiUrl,
      }}
      cacheLocation="localstorage" // Ensures session persists across page refreshes
      useRefreshTokens={true}
    >
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </Auth0Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

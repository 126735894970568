import { Box, Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { styles } from './configuration/style';
import toast from 'react-hot-toast';
import { sharedStyles } from '../../sharedStyles';
import UseWidth from '../../custom-hooks/UseWidth';


const InsuranceSubTabTable = ({ data, error, ErrorMessage }) => {
    const [rowData, setRowData] = useState(null);
    const { width } = UseWidth();
    const navigate = useNavigate();


    const handleButtonClick = (data) => {
        navigate({
            pathname: '/Discoverydetails1',
            search: `?rowData=${encodeURIComponent(JSON.stringify(data))}${'&through=Discovery'}`,
        });
    };


    const veiwPayer = ({ data }) => {
        const objToSend = {
            DOB: data["Date of Birth"],
            ID: data?.ID,
            Requestid: data?.RequestId,
            Patient: data?.Patient,
            Date: data?.Date,
            Status: data?.Status,
            Notes: data?.Notes,
            Insurance: data?.['Insurance Carrier']
        }
        return (
            <div className="flex">
                <Button variant='contained' onClick={() => handleButtonClick(objToSend)} color='success' size='small' sx={styles?.iconCell}>
                    View Details
                </Button>
            </div>
        )
    };



    const columnDefs = () => {
        return [
            { headerName: 'Date', field: 'Date', filter: true, width: 230, resizable: false },
            { headerName: 'Date of Birth', field: 'Date of Birth', filter: true, width: 200, resizable: false },
            { headerName: 'Insurance Carrier', field: 'Insurance Carrier', filter: true, width: 200, resizable: false },
            { headerName: 'Status', field: 'Status', width: 200, filter: true, resizable: false },
            { headerName: 'Notes', field: 'Notes', width: 400, filter: true, resizable: true },
            { headerName: 'Details', field: 'Details', width: 200, filter: true, resizable: false, cellRenderer: veiwPayer },
        ];
    };


    useEffect(() => {
        if (data) {
            const rowsData = data?.insuranceDiscoveryDtosList?.map(({ patientData: { dateOfBirth, firstName, lastName, id }, planCoverageSummary: { status }, requestID, transactionDate, additionalInfo, comboPayerResponses }) => ({
                Date: dayjs(transactionDate).format('MM-DD-YYYY') || 'N/A',
                'Date of Birth': dayjs(dateOfBirth).format('MM-DD-YYYY'),
                'Insurance Carrier': comboPayerResponses.map(item => item.payerName).join(' / ') || 'N/A',
                Notes: additionalInfo || 'N/A',
                Status: status || 'Inactive',
                ID: id || 'N/A',
                Patient: `${firstName} ${lastName}`,
                RequestId: requestID
            }))
            setRowData(rowsData)
        } else {
            setRowData([])
            toast.error("Subscribe Not Found!")
        }
    }, [data, error])

    const gridOptions = {
        pagination: true,
        paginationPageSize: width <= 900 ? 20 : 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    };

    return (
        <Box className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
                overlayNoRowsTemplate='No Subscribers Found!'
            />
        </Box>
    )
}

export default InsuranceSubTabTable

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import styles from './MyTabs.module.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HorizontalBox from '../shared/HorizontalBox';
import { tabStyles } from './styles';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes?.node,
  index: PropTypes?.number?.isRequired,
  value: PropTypes?.number?.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function MyTabs(props) {
  const [value, setValue] = useState(props?.preSelectedTab ?? 0);
  const [previousTab, setPreviousTab] = useState(0)
  const { myTabs, tabInner } = styles
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate()
  
  const handleChange = (event, newValue) => {
    setPreviousTab(value)
    setValue(newValue);
  };

  React.useEffect(() => {
    if (state?.tab) {
      setValue(state?.tab);
    }
  }, [state?.tab])

  React.useEffect(() => {
    props?.preSelectedTab ? setValue(props?.preSelectedTab) : setValue(0)
  }, [props?.preSelectedTab])

  const handleTabChange = () => {
    const { changeTab } = props?.tabs?.[value]
    setValue(changeTab || previousTab)
    setPreviousTab(value)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box className={myTabs}
      sx={tabStyles.tabsContainer}
      >
        <Tabs value={value} className={tabInner} variant='scrollable' scrollButtons={true} allowScrollButtonsMobile onChange={handleChange} aria-label="basic tabs example"
        sx={tabStyles?.tabs}
        >
          {props?.tabs?.length &&
            props?.tabs?.map((tab, index) => {
              return (
                <Tab key={index} label={tab?.label} {...a11yProps(index)}
                  className={value === index ? styles?.activeTab : styles?.inactiveTab}
                />
              )
            })
          }
        </Tabs>
        
        <HorizontalBox 
        sx={tabStyles?.tabRightSide}
        >
        {
          props.tabs[value]?.extraDisplay ? props.tabs[value]?.extraDisplay : null
        }
        <Button
          variant='contained'
          size='small'
          color='success'
          LinkComponent={props.tabs[value]?.link ? Link : 'button'}
          to="/patients"
          state={{ tab: props.tabs[value]?.gotToTab }}
          onClick={() => {
            props.tabs[value]?.backPath ? navigate(props.tabs[value]?.backPath) :
              props.tabs[value]?.goBack ? navigate(-1) :
                props.tabs[value].handleChange ||
                handleTabChange()
          }
          }
          className={styles?.tabButton}
          style={{ display: !props?.tabs[value]?.button ? 'none' : 'block' }}
        >{props?.tabs[value]?.button}</Button>
        </HorizontalBox>
      </Box>
      <Box className={styles?.dataBox}>
        {props?.tabs?.length &&
          props?.tabs?.map((tab, index) => (
            <CustomTabPanel key={index} value={value} index={index}>
              {tab?.value}
            </CustomTabPanel>
          ))
        }
      </Box>
    </Box>
  );
}
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { sharedStyles } from '../../sharedStyles';
import { colors } from '../../utils/colors';
import { getAllPriorAuth } from '../../services/pVerify';
import PriorAuthTableC from '../../components/table/PriorAuthTableC';

const PriorAuthTable = () => {
  const [data, setData] = useState([]);
    // const EligibilityPaginatedTable = () => {
    //   const [rowData, setRowData] = useState(null);
    //     const navigate = useNavigate();
    
    
    
    //     useEffect(async() => {
    //       try {
    //           const data = await getAllPriorAuth();
    //           if(data?.priorAuthorizationEntities) {
    //               const rowsData = data?.priorAuthorizationEntities.map(({ transactionDate, patientData: {firstName, lastName, phone, dateOfBirth}, payerData: {payerName, eligibility} }) => {
    //                   return ({
    //                             "Transaction Date": transactionDate || "",
    //                             Date: new Date(),
    //                             Patient: `${firstName} ${lastName}`,
    //                             Phone: phone || '',
    //                             'Date of Birth': dateOfBirth,
    //                             'Insurance Carrier': payerName || '',
    //                             Status: eligibility === "Yes" ? "Approved" : "Pending",
    //                             Details: ''
    //                   })
    //               })
    //               console.log(rowsData)
    //             setRowData(rowsData);
    //           }
    //       } catch(error) {
    //           console.log(error);
    //       } 
    //       }, []);
  
    //     const statusCellRenderer = (params) => {
    //         const statusValue = params.value;
    //         return <span style={{...styles?.statusStyle,
    //             color:params?.data?.Status === 'Denied' ? colors?.red : 
    //             params?.data?.Status === 'Pending' ? colors?.yellow : 
    //             colors?.green}}>{statusValue}</span>;
    //     };
    
    //     const EligibilityDetailsButton = (params) => {
    //         if (params?.data) {
    //             return (
    //                 <Button
    //                     className="primary"
    //                     style={{...styles?.eligibilityDetailsButton,
    //                         // backgroundColor: params?.data?.Status === 'Denied' ? colors?.red : colors?.green
    //                     }}
    //                     onClick = {()=>{
    //                         params?.data?.Status === 'Pending' &&  window.location.reload();
    //                     }}
    //                 >
    //                    {params?.data?.Status === 'Pending' ? 'Refresh' : 'View Details'}
    //                 </Button>
    //             );
    //         }
    //         return <div></div>;
    //     };
    
    //     const handleEligibilityButtonClick = (data) => {
    //         navigate({
    //             pathname: '/Eligibilitydetails1',
    //             search: `?rowData=${encodeURIComponent(JSON.stringify(data))}`,
    //         });
    //     };
    
  
    //     return (
    //         <div
    //             className="ag-theme-quartz"
    //             style={sharedStyles?.h500}
    //         >
              
    //             <AgGridReact
    //                 pagination={true}
    //                 rowModelType={'infinite'}
    //                 rowData={rowData ? rowData : null}
    //                 rowHeight={60}
    //                 defaultColDef={{ width: 236 }}
    //                 // columnDefs={columnDefs()}
    //                 overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
    //             />
    //         </div>
    //     );
    // };

    const getPriorAuthData = async() => {
      try {
          const response = await getAllPriorAuth();
          if(response?.data?.priorAuthorizationEntities){
            setData(response?.data?.priorAuthorizationEntities);
          }
        } catch (error) {
          console.log(error)
      }
    }

    useEffect(() => {
        getPriorAuthData();
    },[])

  return (
    <Box>

    <Box sx={{ marginTop: '2em' }}>
      <PriorAuthTableC data={data} />
    </Box>
  </Box>
  )

  
}

export default PriorAuthTable
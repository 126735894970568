import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { styles } from "./style";

export const patientGridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    defaultColDef: {
        flex: 1,
        minWidth: 200
    },
    overlayNoRowsTemplate: `
        <span class="ag-overlay-loading-center">
            Loading
        </span>
    `
};


export const PatientNoResultsgridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    defaultColDef: {
        flex: 1,
        minWidth: 200
    },
    overlayNoRowsTemplate: `
        <span class="ag-overlay-loading-center">
            No Results Found!
        </span>
    `
};

export const PatientActionBtn = ({ data }) => {
    return (
        <div>
            <Button variant="contained" size='small' color="success">
                <Link style={styles?.link} to={`/patients/${data?.ID ? data?.ID?.toString()?.replace('#', '') : ''}`}>
                    View Details
                </Link>
            </Button>
        </div>
    );
};


export const handleChangePaRegexs = {
    aetna: {
      lastName: /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,60}$/, 
      firstName: /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,35}$/, 
      suffix: /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,10}$/,
      npi:/^[1-4][0-9]{0,9}/,
      addressLine1: /^[a-zA-Z0-9\s!"&'()+,\-./;?=%@\[\]_{}|#$]{1,55}$/,
      addressLine2: /^[a-zA-Z0-9\s!"&'()+,\-./;?=%@\[\]_{}|#$]{1,55}$/,
      city:/^[a-zA-Z0-9\s!"&'()+,\-./;?=%@[\]_{}|#$]{1,30}$/,
      zipCode:/^\d{1,5}(\d{1,4})?$/,
      contactName:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,60}$/,
      phone:/^[0-9]{1,10}$/,
      extension:/^[0-9]{1,8}$/
    },
  };
  
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { sharedStyles } from '../sharedStyles'

const Footer = () => {
  return (
       <Box sx={[sharedStyles?.textCenter, sharedStyles?.border, sharedStyles?.py1, sharedStyles?.wFull]}>
        <Typography variant='caption' sx={sharedStyles?.footerText}>
          &copy;{dayjs().year()} All Right Reserved | Powered by AppZHealth
        </Typography>
      </Box>
    
  )
}

export default Footer
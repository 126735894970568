import React from 'react'
import PatientForm from './PatientForm'
import ProcedureForm from './ProcedureForm'
import RequestTypeForm from './RequestTypCodeForm'
import RequestingProviderForm from './RequestingProviderForm'

const AetnaForm = () => {
  return (
    <>
    <RequestTypeForm/>
    <RequestingProviderForm />
    <PatientForm/>
    <ProcedureForm/>
    </>
  )
}

export default AetnaForm
import toast from "react-hot-toast"
import { get_payersdata } from "../../services/PayerService"
import { basicPaPayerIds, defaultBasicPaDetailFields } from "../../utils/staticData"
import { get_GetPatientDataById } from "../../services/addPatient"
import { filterSupportedObjects } from "../../utils/reusableFunctions"

 // fetching payers data
 export const fetchPayersData = async (page,setLoaders,setAllPayers,allPayers,loaders) => {
    setLoaders({ ...loaders, payers: true })
    try {
        const payersData = await get_payersdata(page ?? 1,10)
        if (payersData?.data?.length) {
            const loadedPayers = payersData?.data?.map(elem => {
                console.log(filterSupportedObjects(JSON.parse(elem?.priorAuthorization)?.configurations?.[0]?.elements ?? {}),'22',elem?.payerName,JSON.parse(elem?.priorAuthorization)?.configurations?.[0])
                return (JSON.parse(elem?.priorAuthorization)?.configurations?.[0])
            })
            setAllPayers(page > 1 ? allPayers?.concat(loadedPayers) : loadedPayers)
        }
        else { toast.error('No data for payer found, please try again later') }
    }
    catch (e) {
        console.log(e)
    }
    finally {
        setLoaders({ ...loaders, payers: false })
    }
}

// set selected payer
export const handleSelectedPayer = (event, newValue,allPayers,setSelectedPayer,setFormType,setPatientBasicDetails) => {
    if(newValue){
    const matchedVal = allPayers?.find(e => e?.payerName === newValue)
    setSelectedPayer(matchedVal)
    if(basicPaPayerIds?.find(e => e === matchedVal?.payerName)){
        setFormType('basic')
    }
    else {
        setFormType('aministative')
        // setFormType('availity')
    }
    }
    else {
        removePayerInfo(setPatientBasicDetails,setSelectedPayer,setFormType)
    }
  };

// handling basic details of user being changed
export const handleBasicDetailChange = (e,setPatientBasicDetails) => {
    const { name, value } = e.target;
    setPatientBasicDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value, 
    }));
  };

// getting selected patient's complete detail
export const getPatientDetails = async(patientSelected,setLoaders,loaders,setPatientBasicDetails,patientBasicDetails,setSelectedPayer,setFormType)=>{
    if(patientSelected?.id){
        removePayerInfo(setPatientBasicDetails,setSelectedPayer,setFormType)
       try{
        setLoaders({...loaders,patientDetails:true})
        const {data:{firstName,middleName,lastName,dob,memberId,}} = await get_GetPatientDataById(patientSelected?.id)
           setPatientBasicDetails({
               ...patientBasicDetails,
               firstName,
               middleName,
               lastName,
               dob,
               memberId,
           })
        setSelectedPayer({payerName:'Cigna'})
        // setFormType('basic')
        setFormType('aministative')
       }
       catch(e){
        console.log(e)
       }
       finally{
        setLoaders({...loaders,patientDetails:false})
       }
    }
}

// remove payer info
export const removePayerInfo = (setPatientBasicDetails,setSelectedPayer,setFormType) => {
        setSelectedPayer(null)
        setPatientBasicDetails(defaultBasicPaDetailFields)
        setFormType(null)
}

// pagination on scroll
export const handleScroll = (event,setPage,page) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight
    ) {
      const nextPage = page + 1;
      setPage(nextPage);
    }
  };